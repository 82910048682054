<template>
  <v-container fluid>
    <v-card elevation="6" outlined>
      <v-card-text class="pa-0 ma-0">
        <Datatable ref="allergenDatatable"
                   :api-endpoint="ENDPOINTS.DATATABLES.ERP.MEALTYPE"
                   :datatable-headers="datatableHeaders"
                   :excel-columns="excelColumns"
                   :excel-file-name="$t('erp.lang_mealType')"
                   @editEntry="entryEdit"
                   @deleteEntry="entryDelete"
                   @deleteEntries="entriesDelete"
                   show-delete-buttons
                   show-edit-buttons
                   :permissionDelete="true"
                   :permissionEdit="true"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {ENDPOINTS} from '../../../../config'
import {Events} from "../../../../plugins/events";
import mixin from '../../../../mixins/KeyboardMixIns';
import Datatable from "../../../datatable/Datatable";

export default {
  components: {
    Datatable,
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      id:null,
      loading: false,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'uuid',
          width: 80,
          hide : true,
        },
        {text: this.$t('erp.lang_mealTypeName'), value: 'name',sort: "asc"},
        {text: this.$t('generic.lang_icon'), value: 'color',sort: "asc"},
      ],
      excelColumns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: this.$t('generic.lang_name'),
          field: "name",
        },
      ],
    }
  },
  methods: {
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('erp.lang_deleteMealType'),
        text: this.$t('erp.lang_deleteMealTypeType'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(this.id);
          }
          this.axios.post(ENDPOINTS.ERP.MEALTYPE.DELETE, {
            uuids : idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_allergensDeleted'),
                color: "success"
              });
              this.id=null;
              self.$refs.allergenDatatable.getDataFromApi();
              self.$refs.allergenDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading
      });
    },
    entryEdit(entry) {
      this.$router.push({name: 'erp.settings.mealType.edit', params: {id: entry.uuid}});
    },
    entryDelete(entry) {
      this.id = entry.uuid;
      this.deleteData();
    },
    entriesDelete(entries) {
      this.deleteData(entries);
    }
  }
}
</script>