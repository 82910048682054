<template>
  <div>
    <page-title :heading="$t('erp.lang_mealType')"
                :subheading="$t('erp.lang_mealType')"
                :icon=icon
                show-add-button
                :permission-add="true"
    ></page-title>

    <div class="app-main__inner">
      <meal-type-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle.vue";
import MealTypeComponent from "../../../../components/erp/settings/mealType/MealTypeComponent";

export default {
  components: {
    MealTypeComponent,
    PageTitle,
  },
  data: () => ({
    icon: 'mdi mdi-biohazard icon-gradient bg-tempting-azure',
  })
}
</script>